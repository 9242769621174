<template>
    <div class="fixable-column vvt-info-bar mt-2">
        <v-card color="transparent overflow-y-auto" :max-height="leftColumnMaxHeight">
            <v-card-text class="py-0 px-0">
                <InfoBarHelp
                    v-if="helpMode"
                    :helpMode="helpMode"
                    :helpModeField="helpModeField"
                />
                <InfoBarWorkflow
                    v-if="workflowEnabled && workflowMode"
                    :editorMode="editorMode"
                    :template="template"
                    :company="company"
                    :routeId="routeId"
                    :routeWorkflowTaskId="routeWorkflowTaskId"
                    :modifiedByUser="modifiedByUser"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScrollHeaderMixin from '@/components/vvt/ScrollHeaderMixin';
const InfoBarWorkflow = () => import('./Workflow/InfoBarWorkflow.vue');
const InfoBarHelp = () => import('./InfoBarHelp.vue');

export default {
    name: 'InfoBar',
    components: {
        InfoBarWorkflow,
        InfoBarHelp,
    },
    
    mixins: [ScrollHeaderMixin],
    props: {
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
        company: {
            type: [Object, Array],
            default: null,
        },
        routeId: {
            type: [Number, String],
            default: null,
        },
        editorMode: {
            type: String,
            default: null,
        },
        routeWorkflowTaskId: {
            type: [Number, String],
            default: null,
        },
        helpModeField: {
            type: Object,
            default: null,
        },
        workflowEnabled: {
            type: Boolean,
            default: false,
        },
        helpMode: {
            type: Boolean,
            default: false,
        },
        modifiedByUser: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            workflowMode: 'processingActivityModel/getWorkflowModeState'
        }),
        leftColumnMaxHeight () {
            let substract = parseInt(this.$vuetify.application.top) + parseInt(this.$footerHeight());
            return this.$vuetify.breakpoint.smAndDown ? `inherit` : `calc(100vh - ${substract}px)`;
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    mounted () {
        this.initScrollHeader();
    }
};
</script>
